/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

* {
  text-decoration: none !important;
  outline: 0 !important;
}

body {
  /* font-family: 'Quicksand', 'Hind Vadodara', sans-serif; */
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  background-color: #fff;
  color: black;
  /* scroll-behavior: smooth; */
  overflow-x: hidden;
  height: 100%;
}

.dark-mode {
  background-color: black;
  color: white;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}